import { useEffect, useState } from 'react';
import { Box, Button, Container, Divider, Grid, Typography, TextareaAutosize } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { PageNavigator } from '../../common/components/TopNavigator/TopNavigator';
import { useCurrentUser } from '../../contexts/user-context';
import { FormattedUser, useUpdateUser } from '../../common/http/hooks/user';
import Toast from '../../common/components/PopUpMessage/PopUpMessage';
import { isAxiosError } from 'axios';

export type AboutMeForm = yup.InferType<ReturnType<typeof useAboutMeSchema>>;

export const useAboutMeSchema = () => {
  return yup.object({
    proposed_about_me: yup.string().trim().required('This field is required'),
  });
};

const evaluateDisabledState = (user: FormattedUser) => {
  if (user.listenerRole?.proposedAboutMe) {
    return !user.listenerRole.aboutMeRejected;
  }
  return false;
};
function AboutMe() {
  const user: FormattedUser = useCurrentUser();
  const updateUserMutation = useUpdateUser();
  const [disabled, setDisabled] = useState<boolean>(evaluateDisabledState(user));

  useEffect(() => {
    setDisabled(evaluateDisabledState(user));
  }, [user]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      proposed_about_me: user.listenerRole?.proposedAboutMe
        ? user.listenerRole?.proposedAboutMe.replace(/<br \/>/g, '\n')
        : user.listenerRole?.aboutMe?.replace(/<br \/>/g, '\n'),
    },
    resolver: yupResolver(useAboutMeSchema()),
  });

  const submit = (value: AboutMeForm) => {
    setDisabled(true);
    const transformedValue = {
      ...value,
      proposed_about_me: value.proposed_about_me.replace(/\n/g, '<br />'),
    };
    updateUserMutation.mutate(transformedValue, {
      onSuccess: () => {
        Toast.success('Your changes have been saved.');
      },
      onError: (err) => {
        if (isAxiosError(err))
          Toast.error(err.response?.data?.description ?? 'There was a problem adding About Me field.');
        else console.error(err);
      },
      onSettled: () => {
        setDisabled(evaluateDisabledState(user));
      },
    });
  };

  return (
    <>
      <PageNavigator />
      <Container>
        <form noValidate onSubmit={handleSubmit(submit)}>
          <Grid flexDirection="column" container>
            <Grid ml={3} mr={4} mb={4} item>
              <Typography fontWeight={800} variant="h5">
                About Me
              </Typography>
            </Grid>
            <Grid ml={3} mr={4} item>
              <Typography sx={{ textAlign: 'left', fontSize: '18px' }} variant="body1">
                Kindly Human believes in the value of personal stories to help others. Every Peer profile includes a
                brief story that introduces you to our members. Next you will be able to write your own story to
                introduce yourself and what you are passionate about supporting others.
              </Typography>
            </Grid>
            <Box px={3} pt={3}>
              <Typography variant="caption" display="flex" alignItems="center" gap={1}>
                ABOUT ME
              </Typography>
              <Grid item width="100%" mt={1} mb={3}>
                <Divider />
              </Grid>
              <TextareaAutosize
                {...register('proposed_about_me')}
                aria-label="minimum height"
                minRows={3}
                placeholder="Tell your story."
                style={{
                  width: '100%',
                  height: '150px',
                  outline: 0,
                  fontSize: '18px',
                  fontWeight: 400,
                  fontFamily: 'Manrope',
                  padding: '8px 8px 8px 8px',
                  borderRadius: '10px',
                  borderColor: 'background.kindlygray2',
                  resize: 'vertical',
                }}
                disabled={disabled}
              />
              <Grid item width="100%" mt={3} mb={2}>
                <Divider />
              </Grid>
              {errors?.proposed_about_me?.message ? (
                <Typography color={'error.main'} sx={{ fontSize: '15px' }}>
                  {/* @ts-ignore */}
                  {errors.proposed_about_me.message}
                </Typography>
              ) : null}
              {user.listenerRole?.aboutMeRejected ? (
                <Typography color={'error.main'} sx={{ fontSize: '15px', textAlign: 'center' }}>
                  Your message has been rejected. Please try again.
                </Typography>
              ) : null}
              {user.listenerRole?.proposedAboutMe && !user.listenerRole.aboutMeRejected ? (
                <Typography color={'error.main'} sx={{ fontSize: '15px', textAlign: 'center' }}>
                  Your About Me is currently in review.
                </Typography>
              ) : null}
            </Box>
            <Box display="flex" justifyContent="center" pt="28px">
              <Button variant="contained" type="submit" disabled={disabled}>
                Submit
              </Button>
            </Box>
          </Grid>
        </form>
      </Container>
    </>
  );
}

export default AboutMe;
